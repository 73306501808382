import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const customI18n = i18n;

const resources = {
  en: {
    translation: {
      home: 'Home',
      wishlistsList: 'My wishlists',
      login: 'Login',
      logout: 'Logout',
      profile: 'Profile',
      common: {
        noData: 'No data',
        createWishlist: 'Create wishlist',
        save: 'Save',
        cancel: 'Cancel',
        confirm: 'Confirm',
        confirmDeleteTitle: 'Confirm deletion',
      },
      loginForm: {
        title: 'Sign in',
        email: 'Email',
        password: 'Password',
        buttonText: 'Sign in',
      },
      wishlists: {
        createTitle: 'Create wishlist',
        nameField: 'Wishlist name',
        nameFieldError: 'Name is required',
        updateTitle: 'Edit wishlist',
        confirmDeleteMessage: 'Are you sure you want to delete this wishlist? This action is irreversible.',
      },
      homePage: {
        welcomeTitle: 'Welcome on Wishlists',
        welcomeText: 'Easily create, share and manage your wishlists for all your events.',
        plan: {
          title: 'Plan events',
          text: 'Organize your wishes for different events such as birthdays, Christmas, and more.',
        },
        custom: {
          title: 'Customized wishlist',
          text: 'Add and customize items for each wishlist according to your preferences.',
        },
        share: {
          title: 'Share easily',
          text: 'Share your wishlists with family and friends so they can see and contribute to.',
        },
      },
    },
  },
  fr: {
    translation: {
      home: 'Accueil',
      wishlistsList: 'Mes wishlists',
      login: 'Connexion',
      logout: 'Déconnexion',
      profile: 'Profil',
      common: {
        noData: 'Aucune donnée',
        createWishlist: 'Créer une wishlist',
        save: 'Enregistrer',
        cancel: 'Annuler',
        confirm: 'Confirmer',
        confirmDeleteTitle: 'Confirmer la suppression',
      },
      loginForm: {
        title: 'Se connecter',
        email: 'Email',
        password: 'Mot de passe',
        buttonText: 'Se connecter',
      },
      wishlists: {
        createTitle: 'Créer une wishlist',
        nameField: 'Nom de la wishlist',
        nameFieldError: 'Le nom est requis',
        updateTitle: 'Editer une wishlist',
        confirmDeleteMessage: 'Êtes-vous sûr de vouloir supprimer cette wishlist ? Cette action est irréversible.',
      },
      homePage: {
        welcomeTitle: 'Bienvenue sur Wishlists',
        welcomeText: 'Créez, partagez et gérez facilement vos listes de souhaits pour tous vos événements.',
        plan: {
          title: 'Planifiez des événements',
          text: 'Organisez vos souhaits pour différents événements tels que les anniversaires, Noël, et plus encore.',
        },
        custom: {
          title: 'Liste de souhaits personnalisée',
          text: 'Ajoutez et personnalisez des articles pour chaque wishlist en fonction de vos préférences.',
        },
        share: {
          title: 'Partagez facilement',
          // eslint-disable-next-line max-len
          text: 'Partagez vos listes avec votre famille et vos amis pour qu’ils puissent voir vos souhaits et y contribuer.',
        },
      },
    },
  },
};

customI18n.use(initReactI18next).init({
  resources,
  lng: 'fr',
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false,
  },
});

export default customI18n;
