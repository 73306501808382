import { useMutation } from 'react-query';

import wishlistApi from '../../api/wishlistApi';
import { APIWishlistListItem } from '../types';

export default function useUpdateWishlist(wishlistId: string) {
  return useMutation({
    mutationFn: async (wishlistData: { name: string }) => {
      return await wishlistApi.put<APIWishlistListItem>(`/wishlists/${wishlistId}`, wishlistData);
    },
  });
}
