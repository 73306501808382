import React, { createContext, useState, useContext, ReactNode, useEffect, useCallback } from 'react';
import { CircularProgress } from '@mui/material';
import { CredentialResponse } from '@react-oauth/google';

import { User } from '../types';
import useCheckAuth from '../hooks/useCheckAuth';
import useSignIn from '../hooks/useSignIn';
import useLogout from '../hooks/useLogout';
import useSignInWithGoogle from '../hooks/useSignInWithGoogle';

interface AuthContextType {
  user: User | null;
  expiresAt: string | null;
  onGoogleLoginSuccess: (response: CredentialResponse) => Promise<User | undefined>;
  login: (props: { email: string; password: string }) => Promise<User | undefined>;
  logout: () => void;
  isAuthenticated: () => boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [expiresAt, setExpiresAt] = useState<string | null>(null);
  const [isAuthReady, setIsAuthReady] = useState<boolean>(false);
  const { data: userData, isLoading, isError } = useCheckAuth();
  const { mutateAsync: signIn } = useSignIn();
  const { mutateAsync: callLogout } = useLogout();
  const { mutateAsync: signInWithGoogle } = useSignInWithGoogle();

  useEffect(() => {
    if (userData?.user && userData.expiresAt) {
      setUser(userData.user);
      setExpiresAt(userData.expiresAt);
      setIsAuthReady(true);
    }
    return () => {
      setUser(null);
      setExpiresAt(null);
      setIsAuthReady(false);
    };
  }, [userData?.user, userData?.expiresAt]);

  const login = async ({ email, password }: { email: string; password: string }) => {
    const response = await signIn({ email, password });
    const { user, expiresAt } = response ?? {};
    if (user && expiresAt) {
      setUser(user);
      setExpiresAt(expiresAt);
    }
    return user;
  };

  const logout = async () => {
    try {
      await callLogout();
      setUser(null);
      setExpiresAt(null);
    } catch (error) {
      console.error('Logout Error:', error);
    }
  };

  const isAuthenticated = useCallback(() => {
    return Boolean(isAuthReady && user?.id && expiresAt && new Date(expiresAt) > new Date());
  }, [expiresAt, isAuthReady, user?.id]);

  const onGoogleLoginSuccess = async (response: CredentialResponse) => {
    const loginResponse = await signInWithGoogle(response.credential);
    const { user, expiresAt } = loginResponse ?? {};
    if (user && expiresAt) {
      setUser(user);
      setExpiresAt(expiresAt);
    }
    return user;
  };

  return (
    <AuthContext.Provider value={{ user, expiresAt, login, onGoogleLoginSuccess, logout, isAuthenticated }}>
      {(isLoading || !isAuthReady) && !isError ? <CircularProgress /> : children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
