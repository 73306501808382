import React, { useMemo } from 'react';
import { Box, createTheme, ThemeProvider, CssBaseline, Container } from '@mui/material';
import { useAtom } from 'jotai';

import './i18n';
import AppBar from './ui/components/AppBar';
import { darkModeAtom } from './common/atoms/darkMode.atom';
import AppRoutes from './AppRoutes';

const App = () => {
  const [darkMode] = useAtom(darkModeAtom);

  const muiTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
        },
      }),
    [darkMode],
  );

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <AppBar />
      <Container maxWidth="lg" sx={{ maxWidth: '1440px', height: 'calc(100% - 64px)' }}>
        <Box sx={{ padding: 2, height: '100%' }}>
          <AppRoutes />
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default App;
