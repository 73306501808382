import { Box, Typography, LinearProgress, List, ListItem } from '@mui/material';
import React from 'react';

import WishlistItem from './WishlistItem';
import { APIWishlist } from '../types';

export default function Wishlist({ wishlist }: { wishlist: APIWishlist }) {
  const totalItems = wishlist.items.length;
  const reservedItems = wishlist.items.filter(item => item.isBooked).length;
  const progress = (reservedItems / totalItems) * 100;

  return (
    <Box sx={{ padding: 4, borderRadius: 4, boxShadow: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
        {wishlist.name}
      </Typography>

      <Typography sx={!wishlist.description ? { fontStyle: 'italic', mb: 2 } : {}} gutterBottom>
        {wishlist.description ?? 'Aucune description'}
      </Typography>

      <Typography variant="subtitle1">{`Nombre total d'articles : ${totalItems}`}</Typography>

      {wishlist.items.length > 0 && (
        <>
          <Box sx={{ width: '100%', my: 2 }}>
            <LinearProgress
              variant="determinate"
              value={progress}
              color="primary"
              sx={{
                height: 10,
                borderRadius: 5,
                '& .MuiLinearProgress-bar': {
                  borderRadius: 5,
                  backgroundColor: progress === 100 ? 'success.main' : 'primary',
                },
              }}
            />
            <Typography variant="body2">{`${reservedItems} / ${totalItems} articles réservés`}</Typography>
          </Box>

          <List>
            {wishlist.items.map(item => (
              <ListItem key={item.id} alignItems="flex-start" sx={{ mb: 2 }}>
                <WishlistItem item={item} />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Box>
  );
}
