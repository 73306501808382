import React from 'react';
import { Card, CardContent, Box, Typography, Link, Button, CardMedia } from '@mui/material';

import { APIWishlistItem } from '../types';

export default function WishlistItem({ item }: { item: APIWishlistItem }) {
  return (
    <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
      <Card
        variant="outlined"
        sx={{
          display: 'flex',
          width: '100%',
          ml: 2,
          padding: 2,
          borderRadius: 4,
          boxShadow: 3,
          flex: 1,
        }}
      >
        <CardMedia
          component="img"
          src={item.imageUrl ?? 'https://placehold.co/150?text=Aucun+aper%C3%A7u'}
          sx={{ width: 150 }}
        />
        <CardContent sx={{ flex: 1 }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', color: item.isBooked ? 'text.secondary' : 'text.primary' }}
            >
              {item.name}
            </Typography>
            {item.isBooked && 'Déjà offert'}
          </Box>
          <Typography variant="body2" color="textSecondary">
            {item.description}
          </Typography>
          <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Link
              href={item.externalLink}
              target="_blank"
              rel="noopener"
              sx={{
                fontWeight: 'bold',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {"Voir l'article"}
            </Link>
            {!item.isBooked && <Button variant="contained">Offrir</Button>}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
