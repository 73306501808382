import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useAddWishlist from '../hooks/useAddWishlist';
import { WishlistFormData } from '../types';

export default function WishlistCreateForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<WishlistFormData>();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mutateAsync: onSubmit } = useAddWishlist();

  const handleFormSubmit: SubmitHandler<WishlistFormData> = data => {
    onSubmit(data).then(() => {
      navigate('/wishlists');
    });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(handleFormSubmit)}
      sx={{ maxWidth: 400, mx: 'auto', mt: 4, p: 3, boxShadow: 3, borderRadius: 2 }}
    >
      <Typography variant="h5" component="h2" gutterBottom textAlign="center">
        {t('wishlists.createTitle')}
      </Typography>

      <TextField
        label={t('wishlists.nameField')}
        fullWidth
        margin="normal"
        {...register('name', { required: t('wishlists.nameFieldError') })}
        error={Boolean(errors.name)}
        helperText={errors.name ? errors.name.message : ''}
      />

      <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
        {t('common.save')}
      </Button>
    </Box>
  );
}
