import { Dialog, TextField, Button, DialogContent, DialogActions, Typography } from '@mui/material';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { APIWishlistListItem, WishlistFormData } from '../types';
import useUpdateWishlist from '../hooks/useUpdateWishlist';

export default function WishlistsListItemEditModal({
  wishlist,
  handleClose,
  handleEdit,
}: {
  wishlist: APIWishlistListItem;
  handleClose: () => void;
  handleEdit: () => void;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<WishlistFormData>({ defaultValues: { name: wishlist.name } });
  const { t } = useTranslation();
  const { mutateAsync: onSubmit } = useUpdateWishlist(wishlist.id);

  const handleFormSubmit: SubmitHandler<WishlistFormData> = data => {
    onSubmit(data).then(() => {
      handleClose();
      handleEdit();
    });
  };

  return (
    <Dialog
      open
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(handleFormSubmit),
      }}
    >
      <DialogContent>
        <Typography variant="h5" component="h2" gutterBottom textAlign="center">
          {t('wishlists.updateTitle')}
        </Typography>
        <TextField
          label={t('wishlists.nameField')}
          fullWidth
          margin="normal"
          {...register('name', { required: t('wishlists.nameFieldError') })}
          error={Boolean(errors.name)}
          helperText={errors.name ? errors.name.message : ''}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('common.cancel')}
        </Button>
        <Button type="submit" variant="contained" color="primary">
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
