import React, { useState } from 'react';
import { Box, Card, CardContent, IconButton, Stack, Typography } from '@mui/material';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../auth/context/AuthContext';
import DeleteModal from '../../ui/components/DeleteModal';
import useDeleteWishlist from '../hooks/useDeleteWishlist';
import { APIWishlistListItem } from '../types';
import WishlistsListItemEditModal from './WishlistsListItemEditModal';

export default function WishlistsListItem({
  wishlist,
  onUpdate,
  onDelete,
}: {
  wishlist: APIWishlistListItem;
  onUpdate: () => void;
  onDelete: () => void;
}) {
  const { user } = useAuth();
  const { mutateAsync: deleteWishlist } = useDeleteWishlist();
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const navigate = useNavigate();

  return (
    <Card variant="outlined" sx={{ marginBottom: 2 }}>
      <CardContent>
        <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Typography variant="h6" component="div">
              {wishlist.name}
            </Typography>
            <Typography sx={!wishlist.description ? { fontStyle: 'italic' } : {}}>
              {wishlist.description ?? 'Aucune description'}
            </Typography>
            {user && user.id !== wishlist.author.id && (
              <Typography variant="body2" color="text.secondary">
                {wishlist.author.firstName ?? 'Unknown'}
              </Typography>
            )}
          </Box>
          <IconButton onClick={() => navigate(`${wishlist.id}`)}>
            <Visibility />
          </IconButton>
          <IconButton onClick={() => setOpenEdit(true)}>
            <Edit />
          </IconButton>
          <IconButton onClick={() => setOpenDelete(true)}>
            <Delete />
          </IconButton>
          {openDelete && (
            <DeleteModal
              handleClose={() => setOpenDelete(false)}
              handleConfirmDelete={async () => {
                await deleteWishlist(wishlist.id);
                onDelete();
                setOpenDelete(false);
              }}
            />
          )}
          {openEdit && (
            <WishlistsListItemEditModal
              handleClose={() => setOpenEdit(false)}
              wishlist={wishlist}
              handleEdit={onUpdate}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
